<template>
    <div class="libro-cont">

      <div class="libro-abierto" data-aos="fade-up">
        <h2>LA - D5AR: D&P [NIIPLRDDSXXI]</h2>

        <div class="capitulo">
          <h3>Capítulo I: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo II: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo III: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo IV: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

        <div class="capitulo">
          <h3>Capítulo V: <span class="highlight">Disponible muy pronto.</span></h3>
        </div>

      </div>

    </div>

  </template>

  <script>
  export default {
    name: 'LibroCont',
   }
  </script>

<style scoped>
.libro-cont {
  display: flex;
  margin-top: 3em;
  flex-direction: column;
}
.libro-abierto {
  margin-bottom: 3em;
}
h3 {
  color: var(--color-texto);
  font-family: "Lora";
}
.highlight {
  color: var(--color-texto);
}
</style>