<template>
    <div class="proyectos-cont">
    <div class="contenedor2" data-aos="fade-up">
      <h2>PROYECTOS NORMATIVOS</h2>
      <div class="destacados">
        <div v-for="project in proyectos" :key="project.id" class="destacado-card">
          <router-link :to="project.link" class="destacado-link">
            {{ project.name }}
          </router-link>
        </div>
      </div>
      <DestacadosCont v-if="selectedComponent === 'DestacadosCont'" />
    </div>
    </div>
</template>

  <script>
  import DestacadosCont from './DestacadosCont.vue';
  export default {
    name: 'ProyectosCont',
    components: {
    DestacadosCont,
  },
  data() {
    return {
      proyectos: [
        { id: 9, name: 'ROBÓTICA', link: '/destacado/9' },
        { id: 8, name: 'DATOS BIOMÉTRICOS', link: '/destacado/8' },
        { id: 7, name: 'DESARROLLO COMUNITARIO', link: '/destacado/7' },
        { id: 6, name: 'IBUA', link: '/destacado/6' },
        { id: 5, name: 'CRIPTOMONEDAS', link: '/destacado/5' },
        { id: 4, name: 'AMBIENTE Y CONSUMO', link: '/destacado/4' },
        { id: 3, name: 'ACCIONES ESTRUCTURALES', link: '/destacado/3' },
        { id: 2, name: 'INTELIGENCIA ARTIFICIAL', link: '/destacado/2' },
        { id: 1, name: 'CN > 43 BIS/TER', link: '/destacado/1' },
      ],
     };
    }
   }
  </script>

<style scoped>
.proyectos-cont {
    display: flex;
    margin-top: 3em;
    flex-direction: column;
}
h3 {
  color: var(--color-texto);
  font-family: "Lora";
}
.highlight {
  color: var(--color-texto);
}
.contenedor2 {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.destacados {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
.destacado-card {
  background-color: var(--color-fondo);
  border: 1.5px solid var(--color-texto);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.destacado-card:hover {
  transform: scaleX(1.02); 
}
.destacado-link {
  text-decoration: none;
  color: var(--color-base);
  font-size: 0.9em;
  font-family: "Lora";
  text-align: center;
  justify-content: center;
  margin-bottom: 1em;
  display: block;
}

@media (max-width: 768px) {
  .destacados {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 0.5em;
  }
}
</style>