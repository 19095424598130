<template>
    <div class="genia-cont">
      <div class="genia-abierto" data-aos="fade-in">
        <h1>GENIA</h1>
        <p>
          GENIA es un proyecto de inteligencia artificial diseñado para abordar la violencia de género desde múltiples perspectivas: prevención, intervención y seguimiento. Su enfoque integral busca proteger a las víctimas, apoyar a lxs profesionales y concientizar a la sociedad.
        </p>
      </div>
  
      <div class="contenedor2" data-aos="fade-in">
        <div class="project-section">
          <h3>1. Objetivos del Proyecto</h3>
          <ul>
            <li><strong>Prevención:</strong> Desarrollar herramientas de IA que identifiquen patrones de riesgo de violencia en etapas tempranas.</li>
            <li><strong>Intervención:</strong> Crear sistemas que asistan a las víctimas con recursos, orientación y apoyo en tiempo real.</li>
            <li><strong>Apoyo a profesionales:</strong> Proveer herramientas que optimicen el análisis de casos, recopilación de pruebas y toma de decisiones.</li>
          </ul>
        </div>
  
        <div class="project-section">
          <h3>2. Componentes del Proyecto</h3>
          <ul>
            <li><strong>Sistema de Monitoreo y Detección Temprana:</strong> Implementar un sistema basado en análisis de texto y lenguaje natural para identificar señales de abuso emocional, físico o psicológico en mensajes e interacciones.</li>
            <li><strong>Asesoramiento Virtual:</strong> Desarrollar una asistente virtual que proporcione apoyo inmediato, información sobre derechos legales y pasos a seguir, con funciones de detección de urgencia.</li>
            <li><strong>Análisis Predictivo:</strong> Crear modelos de IA que predigan casos potenciales de violencia basados en datos históricos y patrones de interacción.</li>
            <li><strong>Plataforma de Coordinación Interinstitucional:</strong> Facilitar la colaboración entre organizaciones mediante una plataforma de IA que automatice la gestión de casos y coordinación de recursos.</li>
            <li><strong>Recursos Educativos y de Sensibilización:</strong> Generar contenidos personalizados y simulaciones interactivas para concienciar sobre la violencia de género.</li>
          </ul>
        </div>
  
        <div class="project-section">
          <h3>3. Tecnologías y Metodologías</h3>
          <ul>
            <li><strong>Procesamiento de Lenguaje Natural (PLN):</strong> Para analizar conversaciones y detectar señales de abuso.</li>
            <li><strong>Aprendizaje Automático:</strong> Para mejorar predicciones y clasificaciones.</li>
            <li><strong>Análisis de Sentimientos:</strong> Para evaluar tonos emocionales y detectar angustia.</li>
            <li><strong>Reconocimiento de Voz y Video:</strong> Para identificar señales de abuso en entrevistas o audios.</li>
            <li><strong>Blockchain:</strong> Para garantizar la privacidad y seguridad de los datos.</li>
          </ul>
        </div>
  
        <div class="project-section">
          <h3>4. Consideraciones Éticas y Legales</h3>
          <ul>
            <li><strong>Privacidad y Seguridad:</strong> Respetar la privacidad de las víctimas y garantizar la protección de los datos.</li>
            <li><strong>Transparencia:</strong> Asegurar que los modelos sean comprensibles y explicativos.</li>
            <li><strong>Accesibilidad:</strong> Diseñar herramientas accesibles para todas las personas, incluyendo aquellas con discapacidades.</li>
            <li><strong>Colaboración con expertxs:</strong> Integrar a profesionales de distintas disciplinas para supervisar el desarrollo y garantizar la ética del proyecto.</li>
          </ul>
        </div>
  
        <div class="project-section">
          <h3>5. Impacto Esperado</h3>
          <ul>
            <li><strong>Reducción de la Violencia:</strong> Identificar riesgos tempranos y ofrecer intervención inmediata.</li>
            <li><strong>Mayor Acceso a Recursos:</strong> Proveer información y asistencia a las víctimas de manera eficiente.</li>
            <li><strong>Optimización del Trabajo Profesional:</strong> Facilitar el trabajo de lxs expertxs mediante herramientas tecnológicas avanzadas.</li>
            <li><strong>Concientización:</strong> Promover la comprensión de la violencia de género mediante recursos educativos y tecnológicos.</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "GeniaCont",
  };
  </script>
  
  <style scoped>
  .genia-cont {
    display: flex;
    margin-top: 3em;
    flex-direction: column;
  }
  .genia-abierto {
    margin-bottom: 1em;
  }
  h3 {
    color: var(--color-texto);
    font-family: "Lora";
    margin: 0 auto;
  }
  .highlight {
    color: var(--color-texto);
  }
  .contenedor2 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .project-section ul {
    text-align: left;
  }
  </style>
  