import { createApp, nextTick } from 'vue';
import App from './App.vue';
import router from './router';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const app = createApp(App);

AOS.init({
  duration: 800,
  easing: 'ease-in-out',
  once: true,
});

const savedTheme = localStorage.getItem('theme') || 'light';
document.documentElement.setAttribute('data-theme', savedTheme);

app.config.globalProperties.$theme = savedTheme;
app.config.globalProperties.setTheme = (newTheme) => {
  document.documentElement.setAttribute('data-theme', newTheme);
  localStorage.setItem('theme', newTheme);
  app.config.globalProperties.$theme = newTheme;
};

app.use(router);
app.mount('#app');

nextTick(() => {
  AOS.refresh();
});