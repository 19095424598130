import { createRouter, createWebHistory } from 'vue-router';
import InicioCont from './components/InicioCont.vue';
import LibroCont from './components/LibroCont.vue';
import GeniaCont from './components/GeniaCont.vue';
import DestacadosCont from './components/DestacadosCont.vue';
import ProyectosCont from './components/ProyectosCont.vue';

const routes = [
  {
    path: '/',
    name: 'InicioCont',
    component: InicioCont,
    props: true,
  },
  {
    path: '/libro',
    name: 'LibroCont',
    component: LibroCont,
  },
  {
    path: '/genia',
    name: 'GeniaCont',
    component: GeniaCont,
  },  
  {
    path: '/destacado/:id',
    name: 'DestacadosCont',
    component: DestacadosCont,
    props: true,
  },
  {
    path: '/proyectos',
    name: 'ProyectosCont',
    component: ProyectosCont,
    props: true,
},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;